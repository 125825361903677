const config = {
  languages: [
    {
      title: "English",
      titleCode: "ENG",
      flag: "flags/en.jpg",
      key: "en-us",
      key1: "en-gb",
    },
    {
      title: "عربي",
      titleCode: "AR",
      flag: "flags/ae.jpg",
      key: "ar-ae",
      key1: "ar-ae",
    },
    {
      title: "Japnese",
      titleCode: "JPN",
      flag: "flags/ja.jpg",
      key: "ja-jp",
      key1: "jp",
    },
    {
      title: "Sorani",
      titleCode: "SOR",
      flag: "flags/so.jpg",
      key: "so-kr",
      key1: "so",
    },
    {
      title: "Russian",
      titleCode: "RUS",
      flag: "flags/ru.jpg",
      key: "ru",
      key1: "ru",
    },
    {
      title: "Spanish",
      titleCode: "SPA",
      flag: "flags/es.jpg",
      key: "es-es",
      key1: "es",
    },
    {
      title: "Farsi",
      titleCode: "FAR",
      flag: "flags/fa.jpg",
      key: "fa-ir",
      key1: "fa",
    },
    {
      title: "Malay",
      titleCode: "MY",
      flag: "flags/my.png",
      key: "ms-my",
      key1: "my",
    },
  ],
  apiHost: "",
  env: process.env.DEV_ENV || "development",
  englishLanguage: "en-us",
  arabicLanguage: "ar-ae",
  japneseLanguage: "ja-jp",
};
const registerDemoUrl = "https://client.roxcapitals.com/register/forex/demo";
//const registerLiveUrl = "https://my.roxcapitals.com/en/register/forex/live";
//const registerDemoUrl = "https://my.roxcapitals.com/en/register";
const registerLiveUrl = "https://client.roxcapitals.com/login";
const locales = {
  [config.englishLanguage]: {
    titleLabel: "Title",
    title: [
      { key: 1, value: "Mr." },
      { key: 2, value: "Mrs." },
      { key: 3, value: "Miss." },
      { key: 4, value: "Ms." },
      { key: 5, value: "Dr." },
      { key: 6, value: "Prof." },
    ],
    firstName: "First Name",
    lastName: "Last Name",
    home: "Home",
    opening_account: "Opening Account",
    download: "Download",
    proceed: "Proceed",
    full_name: "Full Name",
    email: "Email",
    amount: "Amount",
    account: "Account",
    deposit: "Deposit",
    phone: "Phone",
    country: "Country of Residence",
    btnSignUp: "Register Now",
    success_msg: "Account has been created.",
    error_msg: "Somthing went wrong! Kindly try again.",
    enterLabel: "Enter your ",
    selectCountryLabel: "Select your country of residence",
    isRequired: " is required",
    isNotValid: " is not valid",
    demoAccountTitle: "Create Demo Account",
    tryDemo: "Try Demo",
  },
  [config.arabicLanguage]: {
    titleLabel: "المسمى",
    title: [
      { key: 1, value: "السيد" },
      { key: 2, value: "مدام" },
      { key: 3, value: "الانسة" },
      { key: 4, value: "السيدة" },
      { key: 5, value: ".د" },
      { key: 6, value: ".أ" },
    ],
    home: "الرئيسية",
    firstName: "الاسم الاول",
    lastName: "الاسم الاخير",
    opening_account: "فتح الحسابات",
    download: "تحميل",
    proceed: "متابعة",
    full_name: "الاسم",
    email: "البريد الالكتروني",
    amount: "المبلغ",
    account: "الحساب",
    deposit: "ايداع",
    phone: "رقم الهاتف",
    country: "دولة الاقامة",
    btnSignUp: "فتح حساب",
    success_msg: "تم انشاء الحساب , الرجاء التحقق من البريد الالكتروني",
    error_msg: "حدث خطأ , الرجاء المحاولة مرة اخرى",
    enterLabel: " ادخل ",
    selectCountryLabel: "اختيار دولة الاقامة",
    isRequired: " يرجى ادخال ",
    isNotValid: " غير صحي ",
    demoAccountTitle: "فتح حساب تجريبي",
    tryDemo: "فتح حساب تجريبي",
  },
};

const SUCCESS_MESSAGES = {
  EMAIL_SENT: {
    "ar-ae": "شكرا لتواصلك معنا سنقوم بالرد عليك ف اقرب وقت",
    "en-gb": "We'll contact you to as soon as possible",
    "en-us": "We'll contact you to as soon as possible",
  },
  SUBSCRIBE: {
    "ar-ae": "شكرا لتواصلك معنا سنقوم بالرد عليك ف اقرب وقت",
    "en-gb": "We'll contact you to as soon as possible",
    "en-us": "We'll contact you to as soon as possible",
  },
};

const trans = (key = "", language = "") => {
  return locales[language] ? locales[language][key] : key || key;
};

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3005/api/v1"
    : "https://my.pcgfx.com/api/v1";

const CP_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3010"
    : "https://my.pcgfx.com";

const CP_GATE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3010/Gate"
    : "https://my.pcgfx.com/Gate";

export {
  API_BASE_URL,
  CP_URL,
  CP_GATE,
  config,
  registerDemoUrl,
  registerLiveUrl,
  SUCCESS_MESSAGES,
  trans,
};
