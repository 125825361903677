import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import themeReducer, {initialState} from "../reducers"

// const createNoopStorage = () => {
//   return {
//     getItem(_key) {
//       return Promise.resolve(null);
//     },
//     setItem(_key, value) {
//       return Promise.resolve(value);
//     },
//     removeItem(_key) {
//       return Promise.resolve();
//     },
//   };
// };

// const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, themeReducer);

const createReduxStore = () => {
    const store = createStore(
      persistedReducer,
      initialState, // initial state
    );
    const persistor = persistStore(store);
    return { store, persistor };
  }

export default createReduxStore;